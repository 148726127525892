@use 'variables' as *;


body {
  display: flex;
  flex-direction: column;
  background-color: #293B21;
  position: relative;
}

.sec {

  &-1 {
    flex: none;
    position: relative;
    --ascpectRation: 1920/1080;
    --bgColor: #21263B;
    --bgImage: url('../img/section1/tree-bg-desktop-slice_01_1.png');

    @media only screen and (max-width: 1024px) {
      isolation: isolate;
      --ascpectRation: 430/943;
      --bgImage: url('../img/section1/bg-mobile.png');
      --bgColor: transparent;
      overflow: hidden;

      &:before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        z-index: -1;
        background-color: rgba(33, 38, 59, 0.35);
      }

    }
  }

  &-2 {
    flex: none;
    padding: 0 var(--paddingSide);
    --ascpectRation: 1920/1080;
    --bgColor: rgba(33, 38, 59, 0.7);
    --bgImage: url('../img/section2_3_4/tree-bg-desktop-slice_02_1.png');
    //--bgImage: url('../img/section2_3_4/tree-bg-desktop-slice_02_1.png'), url('../img/section2_3_4/particles_2.png');
    position: relative;
    @media only screen and (max-width: 1024px) {
      --ascpectRation: unset;
      --bgColor: transparent;
      --bgImage: none;
      padding: unset;
    }
  }

  &-3 {
    flex: none;
    padding: 7.34375% 0 0 0;
    --ascpectRation: 1920/1420;
    --bgColor: rgba(33, 38, 59, 0.7);
    --bgImage: url('../img/section2_3_4/tree-bg-desktop-slice_04_1.png');
    //--bgImage: url('../img/section2_3_4/tree-bg-desktop-slice_04_1.png'), url('../img/section2_3_4/particles_2_1.png');
    @media only screen and (max-width: 1024px) {
      --ascpectRation: unset;
      --bgColor: transparent;
      --bgImage: none;
      padding: unset;
    }
  }

  &-4 {
    flex: none;
    padding: 0 var(--paddingSide);
    --ascpectRation: 1920/1080;
    --bgColor: rgba(33, 38, 59, 0.35);
    --bgImage: url('../img/section7/tree-bg-desktop-slice_07_1.png');
    //--bgImage: url('../img/section7/tree-bg-desktop-slice_07_1.png'), url('../img/section2_3_4/particles_2.png');
    @media only screen and (max-width: 1024px) {
      --ascpectRation: 430/719;
      --bgColor: rgba(33, 38, 59, 0.35);
      --bgImage: url('../img/section7/tree-3_2.png');

    }
  }

  &-5 {
    flex: none;
    padding: 0 var(--paddingSide);
    --ascpectRation: 1920/3917;
    --bgColor: transparent;
    --bgImage: url('../img/section5/tree-bg-desktop-slice_05_1.png'), linear-gradient(180deg, rgba(33, 38, 59, 0.7) 0%, rgba(33, 38, 59, 0.35) 100%);
    @media only screen and (max-width: 1024px) {
      --ascpectRation: 430/2253;
      --bgColor: rgba(33, 38, 59, 0.35);
      --bgImage: url('../img/section5/tree-2_2.png'), url('../img/section5/tree-2_3.png');
      background-position: top, bottom;
      padding: unset;
    }

  }

  &-6 {
    flex: none;
    padding: 0 var(--paddingSide);
    --ascpectRation: 1920/1338;
    --bgColor: rgba(33, 38, 59, 0.35);
    --bgImage: url('../img/section6/tree-bg-desktop-slice_06_1.png');
    //--bgImage: url('../img/section6/tree-bg-desktop-slice_06_1.png'), url('../img/section6/particles_2.png');
    @media only screen and (max-width: 1024px) {
      --ascpectRation: 430/640;
      --bgColor: rgba(33, 38, 59, 0.35);
      --bgImage: url('../img/section6/tree-2_3.png');
    }
  }

  &-7 {
    flex: none;
    padding: 0 var(--paddingSide);
    position: relative;
    --ascpectRation: 1920/1080;
    --bgColor: rgba(33, 38, 59, 0.7);
    --bgImage: url('../img/section2_3_4/tree-bg-desktop-slice_03_1.png');
    //--bgImage: url('../img/section2_3_4/tree-bg-desktop-slice_03_1.png'), url('../img/section7/particles_2.png');
    @media only screen and (max-width: 1024px) {
      --ascpectRation: 430/719;

      --bgImage: none;
      --bgColor: rgba(33, 38, 59, 0.35);

    }
  }

  //
  //&-mob{
  //  display: none;
  //  @media screen and (max-width: $mediaS) {
  //    display: flex;
  //  }
  //}
  //&-desk{
  //  display: flex;
  //  @media screen and (max-width: $mediaS) {
  //    display: none;
  //  }
  //}


  &-8 {
    position: relative;
    flex: none;
    padding: 0 var(--paddingSide);
    --ascpectRation: 1440/1003;
    --bgColor: rgba(33, 38, 59, 0.35);
    --bgImage: url('../img/section8/tree-bg-desktop-slice_08_1.png');
    //--bgImage: url('../img/section8/tree-bg-desktop-slice_08_1.png'), url('../img/section8/particles_2.png');
    @media only screen and (max-width: 1024px) {
      //--ascpectRation: 430/1210;
      --ascpectRation: 430/1177;
      --bgColor: rgba(33, 38, 59, 0.35);
      --bgImage: url('../img/section8/tree-3_2.png');
    }
  }

  &, &-wrapper {
    width: 100%;
    background-color: var(--bgColor, transparent);
    background-image: var(--bgImage, none);
    background-size: 100% 100%;
    @media only screen and (max-width: 1024px) {
      background-size: contain;
      background-repeat: no-repeat;

    }

  }


  display: flex;
  align-items: center;
  aspect-ratio: var(--ascpectRation);

  &-d {
    &-c {
      flex-direction: column;
    }

    &-r {
      flex-direction: row;
    }
  }

  &-wrapper {
    display: contents;
    @media only screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: stretch;
      aspect-ratio: var(--ascpectRationLG, 1);
      padding-left: 8.1395348837%;
      padding-right: 8.1395348837%;

      &-1 {
        --ascpectRationLG: 430/2399;
        --bgColor: rgba(33, 38, 59, 0.35);
        --bgImage: url('../img/section-group-mobile-bg.png');
        background-position: bottom, top;
      }

      .sec {
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
      }
    }

    @media only screen and (max-width: 430px) {
      .txt-h-2 {
        font-size: 11.6279069767vw;
      }

      .txt-c-l {
        font-size: 3.6511627907vw;
      }
    }
  }
}

.block {
  display: flex;

  &-l {
    max-width: 1581px;
  }

  &-md {
    max-width: 1520px;
  }

  &-sm {
    max-width: 1368px;
  }

  &-xs {
    max-width: 1000px;
  }

  &-center {
    margin: 0 auto;
  }

  &-column {
    flex-direction: column;
  }

  &-row {
    flex-direction: row;
  }

  &-row-reverse {
    flex-direction: row-reverse;
  }
}


a {
  cursor: pointer;
}

a img {
  border: 0;
}

.img {
  display: block;
  width: 100%;
  object-fit: contain;
}

//section1//
.mintBtn {
  display: flex;
  flex-direction: column;
  max-width: 820px;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  //margin-top: min(64 * $wp, 64px);
  gap: min(13 * $wp, 13px) min(13 * $wp, 13px);
  @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
    //margin-top: min(44 * $wp, 44px);
    gap: min(8 * $wp, 8px) min(16 * $wp, 16px);
  }
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 680px) {
    align-items: end;
  }


  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:nth-child(2) {
      margin-left: 30px;
      @media only screen and (max-width: 1024px) {
        margin: 0 0 10px 0;
      }
    }

    &:nth-child(3) {
      margin-left: 60px;
      @media only screen and (max-width: 1024px) {
        margin: 0;
      }
    }

    @media only screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }

    .available {
      font-size: 12px;
      line-height: 157%;
      color: var(--txtColorPrimary);
      font-weight: 700;
      margin-top: -4px;
      @media only screen and (max-width: 430px) {
        font-size: 13px;
      }


    }

    .amount {
      color: var(--txtColorSecondary);
      font-weight: 700;
      font-size: 12px;
      line-height: 157%;
      @media only screen and (max-width: 430px) {
        font-size: 13px;
      }
    }
  }
}

.intro {
  width: 100%;
  align-items: center;
  position: relative;
  display: flex;
  //padding: 20px 0;
  height: 100%;

  &-wrapper {
    max-width: 1760px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    height: 100%;
    padding: 0 0 0 120px;
    width: 100%;

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      padding: unset;
    }

  }

  &-slider {
    height: 100%;
    flex-basis: 56%;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;

    @media only screen and (min-width: 1024px) {
      padding-top: 120px;
      padding-bottom: min(30 * $wp, 30px);

      .title {
        margin-top: auto;
      }
    }

    @media only screen and (max-width: 1024px) {
      padding: 120px 20px;
      justify-content: center;
    }

    .slide {
      &-description {
        margin-top: min(20 * $wp, 20px);
        @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
          margin-top: min(14 * $wp, 14px);
        }
        @media only screen and (max-width: 1024px) {
          margin-top: 18px;
        }
        @media only screen and (max-width: 420px) {
        }
      }
    }

    .countdown {
      margin-top: min(28 * $wp, 28px);
      margin-bottom: min(40 * $wp, 40px);
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: min(16 * $wp, 16px);
      @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
        gap: min(12 * $wp, 12px);
      }
      @media only screen and (max-width: 1024px) {
        margin-top: 22px;
        max-width: 200px;
        flex-wrap: wrap;
        margin-bottom: 33px;
        gap: 14px;

      }

      &-item {
        flex: none;
        gap: 4px;
        width: min(134 * $wp, 134px);
        aspect-ratio: 134/156;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: min(12 * $wp, 12px);
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #FFFFFF;
        box-shadow: 0 min (4 * $wp, 4px) min(4 * $wp, 4px) rgba(0, 0, 0, 0.25);
        @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
          border-radius: min(8 * $wp, 8px);
          width: min(100 * $wp, 100px);
        }
        @media only screen and (max-width: 1024px) {
          width: 81px;
          height: 95px;
          border-radius: 8px;
          backdrop-filter: blur(3px);

        }

        &-digit {
          font-size: min(80 * $wp, 80px);
          line-height: 1;
          font-weight: 700;
          font-family: Tungsten, sans-serif;
          color: #FFFA8C;
          @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
            font-size: min(56 * $wp, 56px);
          }
          @media only screen and (max-width: 1024px) {
            font-size: 48px;
            line-height: 52px;
          }

        }

        &-label {
          font-size: min(20 * $wp, 20px);
          line-height: 1;
          font-weight: 700;
          color: #979797;
          text-transform: uppercase;
          font-family: Aller;
          @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
            font-size: min(14 * $wp, 14px);
          }
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 13px;
          }
        }
      }
    }

  }


  &-btn {
    margin-top: min(74 * $wp, 74px);
  }

  &-social {
    display: flex;
    //margin-top: min(100 *$wp, 100px);
    //margin-bottom: min(50 *$wp, 100px);
    //margin: 100px 0 50px 0;
    margin: auto 0 0 0;
    @media only screen and (max-width: 1024px) {
      display: none;
    }

  }

  &__media {
    position: absolute;
    display: flex;
    height: 100%;
    right: 0;

    @media only screen and (max-width: 1024px) {
      top: 80px;
      width: 100%;
      bottom: 0;
      height: auto;
    }

    .mediaImg {
      display: block;
      width: auto;
      height: 100%;
      max-height: 100%;

      &.mobile {
        display: none;
      }

      @media only screen and (max-width: 1024px) {
        aspect-ratio: unset;
        position: relative;
        height: min-content;
        left: 0;
        top: 0;
        width: calc(100% + 22px);
        display: none;

        &.mobile {
          display: block;
          z-index: -2;
          top: -110px;
        }
      }
    }

    &-phone {
      position: absolute;
      display: flex;
      left: 0;
      bottom: 15px;
      max-height: 655px;
      height: 90%;
      z-index: 1;
      pointer-events: none;

      .img {
        display: block;
        height: 100%;
        width: auto;
        object-fit: contain;
        object-position: center center;
      }
    }

    &-footer {
      position: absolute;
      right: 0;
      bottom: 90px;
      width: 100%;
      max-width: 600px;
      height: 68px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: rgba(38, 42, 57, 0.8);
      padding-right: 42px;
      z-index: 0;

      .donationLogo {
        margin-left: 30px;
        height: 34px;
        width: auto;

        &.celo {
          height: 26px;
        }

        .logo {
          display: block;
          width: auto;
          height: 100%;
        }
      }

      &-desc {
        position: absolute;
        top: 100%;
        right: 42px;
        transform: translateY(8px);
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px #000000;
      }
    }

    @media screen and (max-width: $mediaS) {
      &-phone {
        bottom: unset;
        top: 230px;
        max-height: 480px;
      }

      &-footer {
        width: 100%;
        max-width: unset;
        bottom: 40px;
      }
    }

    @media screen and (max-width: 680px) {
      &-phone {
        max-height: 380px;
      }

    }


    @media only screen and (max-width: 480px) {
      &-phone {
        max-height: 330px;
      }
    }
  }
}

.readmore-btn {
  margin-top: min(40 * $wp, 40px);
  margin-bottom: min(50 * $wp, 50px);

}


//section2

.about {
  width: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: center;


  gap: min(62 * $wp, 62px);
  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    justify-content: center;


  }

  &__media {
    display: flex;
    aspect-ratio: 1;
    max-height: 944px;
    height: 87.4%;
    @media only screen and (max-width: 1024px) {
      aspect-ratio: 1;
      margin-bottom: 8px;
      max-width: 72%;
      flex: unset;
    }
  }

  .mediaImg {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }


  &-subtxt {

    margin-top: min(39 * $wp, 39px);
    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      margin-top: min(28 * $wp, 28px);
    }
    @media only screen and (max-width: 1024px) {
      margin-top: 18px;

    }

  }

  &-mail {
    font-size: 18px;
    text-decoration: underline;
    color: var(--txtColorSecondary);
  }

  &-logo {
    display: flex;
    align-items: center;
    gap: 5px;

    .ethichub-logo {
      vertical-align: bottom;
      width: 20%;
      height: 100%;
      display: inline-block;
      padding-left: 4px;
      margin-left: 10%;
      padding-top: 4px;
      @media only screen and (max-width: 1024px) {
        margin-left: 4px;
        margin-right: 4px;
      }
      @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
        margin-left: 0;
      }

    }
  }

  &-donation {
    display: flex;
    align-items: center;
    gap: 31px;
    width: 100%;
    margin-top: min(30 * $wp, 30px);
    margin-bottom: min(5 * $wp, 5px);
    @media only screen and (max-width: 1024px) {
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .donationLogo {
      width: 100%;
      max-width: 114px;

      img {
        display: block;
        width: 100%;
      }

      @media only screen and (max-width: 1024px) {
        max-width: 90px;
      }
    }
  }
}

.show-mob {
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
}


//section3

.welcome {
  padding: 20px 0;
  align-items: center;
  max-width: 1352px;
  width: 70.4166666667%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &__media {
    display: flex;
    width: 100%;

    max-width: 585px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    overflow: hidden;

    .mediaImg {
      display: block;
      width: 100%;
      object-fit: contain;
      aspect-ratio: 1352/759;
    }
  }

  &__video {
    border: 5px solid rgba(255, 255, 255, 0.3);
    display: flex;
    width: 100%;
    aspect-ratio: 560/315;
    //max-width: 685px;
    max-width: 560px;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
    }
  }

  &-title {
    margin-bottom: min(38 * $wp, 38px);
    //margin-top: min(100 * $wp, 100px);
    text-align: center;
    white-space: nowrap;
    @media only screen and (max-width: 1024px) {
      white-space: unset;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 50px;

    }
  }
}

//section4

.info {
  justify-content: center;
  align-items: center;
  text-align: start;
  width: 100%;
  padding: 20px 0;
  gap: min(100 * $wp, 100px);
  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    flex: unset;
  }

  &__media {
    display: flex;
    aspect-ratio: 1;
    max-height: 756px;
    height: 100%;
    @media only screen and (max-width: 1024px) {
      aspect-ratio: 1;
      max-width: 75%;
      flex: unset;
      padding-bottom: 0px;
    }

    .mediaImg {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}

//section7


.play {
  padding: 20px 0;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    height: unset;
  }

  &__media {
    position: absolute;
    display: flex;
    aspect-ratio: 845/793;
    height: 73.42%;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    @media only screen and (max-width: 420px) {

    }
    @media only screen and (max-width: 1024px) {
      position: unset;
      width: 271px;
      height: auto;
      transform: none;
      flex: unset;
      margin-bottom: 46px;
      flex-basis: unset;
      aspect-ratio: unset;
    }

    .mediaImg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &-reverse {
    flex-direction: row-reverse;
  }

  &-btn {
    &-hide {
      display: none;
    }

    margin-top: min(65 * $wp, 65px);
    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      margin-top: min(46 * $wp, 46px);
    }
  }
}


.flex {
  &-40 {
    flex-basis: 40%;
  }

  &-50 {
    flex-basis: 50%;
  }

  &-60 {
    flex-basis: 60%;
  }
}


//section6


.roadmap {
  position: relative;
  max-width: 1510px;
  width: 100%;
  aspect-ratio: 1510/580;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  margin: auto;
  gap: min(55 * $wp, 55px);
  @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
    gap: min(38 * $wp, 38px);
    aspect-ratio: 1510/710;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }

  &__title {
    text-shadow: 0 4px 10px #000000;
    margin-bottom: 18px;
  }

  &-vertical {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #616161;
    box-shadow: inset 0 0 min(10 * $wp, 10px) #FFFFFF;
    border-radius: min(19 * $wp, 19);
    height: 12px;
    width: 100%;
    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      border-radius: min(14 * $wp, 14);
    }

    .progress {
      background: #FFBE11;
      box-shadow: 0 min(4 * $wp, 4px) min(17 * $wp, 17px) #FECC46, inset 0 0 min(10 * $wp, 10) #FFFFFF;
      border-radius: min(19 * $wp, 19);
      height: 12px;
    }
  }


  &__heading {
    padding-top: min(141 * $wp, 141px);
    max-width: min(656 * $wp, 656px);
    align-items: center;
    text-align: center;
    @media only screen and (max-width: 1024px) {
      max-width: unset;
    }
  }

  .roadmapCol {
    position: relative;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    text-align: center;

    &:nth-child(odd) {
      justify-content: flex-end;
    }

    .avatar {
      position: relative;
      width: min(153 * $wp, 153px);
      aspect-ratio: 1;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
        width: min(200 * $wp, 200px);
      }

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .avatarImg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;

      }
    }

    .itemNum {
      font-size: min(60 * $wp, 60px);
      font-weight: 700;
      margin-top: min(10 * $wp, 10px);
      margin-bottom: min(21 * $wp, 21px);
      @media only screen and (max-width: 1024px) {
        font-size: 37px;
        line-height: 60px;
        font-weight: 700;
      }
    }

    .dot {
      width: min(66 * $wp, 66px);
      height: min(66 * $wp, 66px);
      background: linear-gradient(180deg, #4FE72A 0%, #1E8500 115.22%);
      border: 2px solid white;
      border-radius: 50%;
      display: flex;
      flex: 0 0 min(66 * $wp, 66px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: min(32 * $wp, 32px);
      color: #E1E4FF;
      font-weight: 700;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
        border-width: 2px
      }


      &.active {
        &:after {
          opacity: 0;
        }
      }

    }

    .block-column {
      text-align: center;

      p {
        max-width: 490px;
      }
    }
  }

  &__roadmap {
    padding-top: min(140 * $wp, 140px);
    padding-bottom: min(68 * $wp, 68px);
    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      padding-top: min(46 * $wpm, 46px);
      padding-bottom: min(14 * $wpm, 14px);
    }
  }

}


.roadmap-wrapper {

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 20px;
  margin-top: auto;

  @media only screen and (min-width: 1025px) {
    display: none;
  }

}

.roadmap__mob {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 20px;

  &-item {
    background: rgba(150, 150, 150, 0.19);
    backdrop-filter: blur(2.5px);
    border-radius: 18px;
    width: 165px;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 26px 10px;
    flex: none;
    @media only screen and (max-width: 420px) {
      width: 100%;
    }

    .itemNum {
      @media only screen and (max-width: 1024px) {
        font-size: 37px;
        line-height: 60px;
        font-weight: 700;
      }
    }
  }

  .avatar-mob {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .txt-mob {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  //max-width: min(1666 * $wp, 1666px);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.99);
  overflow-x: hidden;
  overflow-y: auto;

  &-btn {
    position: absolute;
    right: 24px;
    top: 24px;
    outline: none;
    padding: 8px;
    display: block;
    border: none;
    background: none;
    opacity: .5;
    cursor: pointer;

    svg {
      display: block;
      height: 34px;
      width: auto;
    }

    @media screen and (max-width: 1024px) {
      top: 20px;
      right: 20px;

      svg {
        height: 28px;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  &__wrapper {
    display: flex;
    width: 100%;
    padding: min(85 * $wp, 85px) min(130 * $wp, 130px) min(95 * $wp, 95px) min(130 * $wp, 130px);
    gap: min(106 * $wp, 106px);
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
    max-width: 1666px;
    max-height: 908px;
    margin: auto;

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      text-align: center;
      max-width: 1666px;
      max-height: unset;
      aspect-ratio: unset;
    }

    &-media {
      flex-direction: column;
      justify-content: flex-start;

      .popup-wrapper--content {
        position: absolute;
        top: 24px;
        height: 50px;
        line-height: 50px;
        left: 0;
        width: 100%;
        text-align: center;
        user-select: none;
        pointer-events: none;
        z-index: 1;

        @media only screen and (max-width: 1024px) {
          top: 0;
          height: min(85 * $wpm, 85px);
          line-height: min(85 * $wpm, 85px);
          font-size: 28px;
        }
      }
    }

    &__game {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }


  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      .txt-c-s {
        font-size: min(20 * $wp, 20px);
      }
    }

    @media only screen and (max-width: 1024px) {
      text-align: center;
      align-items: center;
      justify-content: flex-start;
      flex: unset;

      h2 {
        font-size: min(60 * $wpm, 60px);
        margin-bottom: min(19 * $wpm, 19px);
      }
    }

    &-link {
      text-transform: capitalize;
      text-decoration: underline;
      padding-top: 20px;
    }

  }

  &-media {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    object-position: center center;

    &-wrap {
      width: 80vw;
      height: 70vh;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      overflow: hidden;
    }
  }
}


.info-popup {
  &__wrapper {
    width: 70%;
  }

  &-content {
    text-align: center;
    align-items: center;

  }
}

body.webPopup-container {
  overflow: hidden;
}





