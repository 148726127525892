@use 'variables' as *;

.txt {
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;

  &-h {
    font-family: Tungsten, sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    &-2 {
      font-size: min(100 * $wp , 100px);
      line-height: 97.8%;
      @media only screen and (max-width: 1024px) {
        font-size: 60px;
      }
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: min(106 * $wp , 106px);

      }
    }
    &-3 {
      font-size: min(80 * $wp, 80px);
      line-height: 97.8%;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: min(92 * $wp , 92px);
      }
      @media only screen and (max-width: 1024px){
        font-size: 50px;
      }

    }
    &-4 {
      font-size: min(100 * $wp, 100px);
      line-height: 109.8%;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: min(70 * $wp , 70px);
      }
      @media only screen and (max-width: 1024px){
        font-size: 50px;
      }
    }

    &-capitalize{
      text-transform: capitalize;
    }

  }
  &-c{
    font-family: aller, sans-serif;
    font-weight: 200;
    &-xl{
      font-weight: 400;
      font-size: min(58 * $wp, 58px);
      line-height: 109.8%;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: min(40 * $wp , 40px);
      }
      @media only screen and (max-width: 1024px){
        font-size: 28px;
      }
    }
    &-l{
      font-weight: 400;
      font-size: min(26 * $wp, 26px);
      line-height: 153.3%;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: min(25 * $wp , 25px);
      }
      @media only screen and (max-width: 1024px) {
        //font-size: 20px;
        font-size: min(18 * $wpm , 18px);
      }
    }
    &-m{
      font-size: min(26 * $wp, 26px);
      line-height: 153.3%;
      font-weight: 400;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: min(25 * $wp , 25px);
      }
      @media only screen and (max-width: 1024px){
        font-size: 28px;
      }
    }
    &-subTitle{
      font-style: normal;
      font-weight: 700;
      font-size:  25px;
      line-height: 109.8%;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: 20px;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 18px;
      }

    }
    &-title{
      font-weight: 700;
      font-size: min(30 * $wp, 30px);
      line-height: 157.3%;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: min(26 * $wp , 26px);
        font-weight: 700;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 22px;
        line-height: 25px;
      }
    }
    &-s{
      font-size: min(20 * $wp, 20px);
      //line-height: 157.3%;
      @media screen and (min-width: $mediaS) and (max-width: $mediaM){
        font-size: min(16 * $wp , 16px);
      }
      @media screen and ( max-width: 1320px) {
        font-size: 13px;
      }
    }
    &-xs{
      font-size:  18px;
      font-weight: 400;
      line-height: 157.3%;
      @media screen and ( max-width: 1320px) {
        font-size: 16px;
        line-height: 23px;
      }

    }
  }

  &-uppercase{
    text-transform: uppercase;
  }

  &-w-700{
    font-weight: 700;
  }
}

.txt-primary{
  color: var(--txtColorPrimary);
}

.txt-secondary{
  color: var(--txtColorSecondary)
}


