@use "fonts";
@use "reset";
@use "variables";
@use "buttons";
@use "header";
@use "footer";
@use  'section';
@use "section1";
@use "section2";
@use "section3";
@use "titleBlock";
@use "typo";
@use "team";
@use "timeline";
@use "loader";
@use "privacy-terms";


.toast-container {
  font-size: 20px;
}

