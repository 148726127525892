@use "variables" as *;

.timeline {
  position: relative;
  max-width: 1358px;
  width: 100%;
  height: fit-content;
  justify-content: center;
  margin: auto;
  gap: 15px;
  @media only screen and (max-width: 1024px) {
    margin: unset;
    padding: 0 min(21 * $wpm, 21px) min(50 * $wpm, 50px);
    flex: 1;
  }

  &-horizontal {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    background: #616161;
    box-shadow: inset 0 0 10px #FFFFFF;
    border-radius: min(19 * $wp, 19);
    height: 100%;
    width: 12px;
    z-index: 1;

    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      border-radius: min(14 * $wp, 14);
    }
    @media only screen and (max-width: 1024px) {
      width: 5px;
      box-shadow: inset 0 0 4px #FFFFFF;
      border-radius: 8px;
    }

    .progress {
      background: #FFBE11;
      box-shadow: 0 min(4 * $wp, 4px) min(17 * $wp, 17px) #FECC46, inset 0 0 min(10 * $wp, 10) #FFFFFF;
      border-radius: min(19 * $wp, 19);
      width: 12px;
      @media only screen and (max-width: 1024px) {
        width: 5px;
        box-shadow: inset 0 0 4px #FFFFFF;
        border-radius: 8px;
      }
    }
  }

  .timelineRow {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;

    &:nth-child(odd) {
      flex-direction: row-reverse;

      .avatar-container {
        justify-content: flex-start;
      }

      .block-column {
        text-align: right;
        align-items: flex-end;
      }

      .content {
        background: linear-gradient(90deg, rgba(25, 25, 33, 0) 6.92%, #191921 60.47%);
        padding: 25px 60px 29px 33px;
        border-radius: 20px 0 0 20px;
        transform: translateX(calc((min(100 * $wp, 100px)) + min(20 * $wp, 20px)));

        @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
          transform: translateX(calc((min(70 * $wp, 70px)) + min(20 * $wp, 20px)));
        }

        @media only screen and (max-width: 1024px) {
          background: unset;
          transform: unset;
          padding: 0;
        }
      }
    }

    .avatar {
      position: relative;
      width: min(328 * $wp, 328px);
      aspect-ratio: 1.09009009009;
      //@media screen and (min-width: $mediaS) and (max-width: $mediaM){
      //  width: min(230 * $wp , 230px);
      //}

      @media only screen and (max-width: 1024px) {
        width: 128px;
      }

      &-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1 0 0;
        max-width: 460px;
      }

      .avatarImg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        -webkit-mask-size: contain;
        mask-size: contain;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center center;
        -webkit-mask-position: center center;
        -webkit-mask-image: url(/assets/img/timeline/mask-border.svg#mask-border);
        mask-image: url(/assets/img/timeline/mask-border.svg#mask-border);

        //&-overlay {
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  z-index: 1;
        //  -webkit-mask-image: url(/assets/img/timeline/mask-border.svg#mask-border);
        //  mask-image: url(/assets/img/timeline/mask-border.svg#mask-border);
        //}
      }
    }

    .dot {
      width: min(40 * $wp, 40px);
      height: min(40 * $wp, 40px);
      background: linear-gradient(180deg, #4FE72A 0%, #1E8500 115.22%);
      border: 2px solid #FFFAE2;
      border-radius: 50%;
      display: flex;
      flex: 0 0 min(40 * $wp, 40px);
      margin: 0 min(100 * $wp, 100px);
      position: relative;
      z-index: 2;

      &.active {
        &:after {
          opacity: 0;
        }
      }

      @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
        margin: min(70 * $wp, 70px);
        border-width: 2px;
      }

      @media only screen and (max-width: 1024px) {
        width: 18px;
        height: 18px;
        border: 1px solid #FFFAE2;
        flex: unset;
      }
    }

    .block-column {
      flex: 1 0 0;

      p {
        max-width: 490px;
        @media screen and(max-width: $mediaS) {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .content {
      background: linear-gradient(90deg, #191921 37.02%, rgba(25, 25, 33, 0) 97.5%);
      border-radius: 0 20px 20px 0;
      padding: 25px 33px 29px 60px;
      max-height: 253px;
      max-width: 460px;
      transform: translateX(calc(-1 * (min(100 * $wp, 100px)) - min(20 * $wp, 20px)));

      @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
        transform: translateX(calc(-1 * (min(70 * $wp, 70px)) - min(20 * $wp, 20px)));
      }

      @media only screen and (max-width: 1024px) {
        background: unset;
        transform: unset;
        padding: 0;
      }
      p{
        font-size: 15px;
        line-height: 1.5;
        font-weight: 400;
      }
    }
  }

  &__title {
    padding-top: min(140 * $wp, 140px);
    padding-bottom: min(68 * $wp, 68px);
    text-shadow: 0 4px 10px #000000;
    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      padding-top: min(98 * $wp, 98px);
      padding-bottom: min(48 * $wp, 48px);
    }
    @media only screen and (max-width: 1024px) {
      padding-bottom: 70px;
    }
  }
}


.mb-s {
  margin-bottom: min(12 * $wp, 12px);
  @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
    margin-bottom: min(8 * $wpm, 8px);
  }
}

.mb-xs {
  margin-bottom: min(8 * $wp, 8px);
}
