$mediaS: 1024px;
$mediaM: 1560px;
:root{
  --txtColorPrimary:#ffffff;
  --txtColorSecondary:#E1E4FF;
;
  --txtColorActive:#FECC46;
  --txtColorInActive:#6577A3;
  --txtColorOptional1:#072942;;
  --txtColorOptional2:#FFFFFF;
  --txtColorOptional3:#C7351E;
  --txtColorOptional4:#6C85C4;
  --txtColorOptional5:#FFFA8C;
  --txtColorOptional6:#979797;
  --txtColorOptional7:#196AA4;

  --paddingSide:4%;
  --paddingTop:100px;
}


$wp: 100vw / 1920;
$wpm: 100vw / 430;
