* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
a{
  @media (hover) {
    cursor: pointer;
  }
}
app-root,app-home,app-footer,app-header, app-connect-wallet {
  display: contents;
}
:root {
  background-color: #ffffff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 40px;
  font-family: aller,sans-serif;

  &::-webkit-scrollbar {
    width: 8px;
    height: 0;
  }
  &::-webkit-scrollbar-button {
    width: 6px;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: #FECC46;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-track {
    background: #3E4866;

  }
  &::-webkit-scrollbar-track:hover {
    background: #3E4866;
  }
  &::-webkit-scrollbar-track:active {
    background: #3E4866;
  }
  &::-webkit-scrollbar-corner {
    background:#3E4866;
  }

}


