@use 'variables' as *;

.btn {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  filter: brightness(1);
  opacity: 1;
  z-index: 1;
  transition: opacity .2s linear, filter .2s linear;

  &:focus, &:active {
    border: none;
    outline: none;
  }

  &:hover, &:active {
    filter: brightness(1.3);
  }

  &:disabled, [aria-disabled] {
    cursor: not-allowed;
  }

  &-col {
    flex-direction: column;
  }

  &__1, &__2, &__3, &__4 {
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    line-height: 109.8%;
    display: flex;
    padding: 0 20px 5px 20px;
    background-size: cover;
    background-position: center;

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__2, &__3, &__4 {
    width: 216px;
    aspect-ratio: 216/59;
    font-size: 20px;

    .txt-c-s {
      font-size: 20px;
    }

    @media screen and (max-width: $mediaS) {
      width: 200px;
      font-size: 15px;

      .txt-c-s {
        font-size: 12px;
      }
    }

  }

  &__1 {
    background-image: url("~assets/img/button/Button-1.png");
    width: 216px;
    aspect-ratio: 216/59;
    font-size: 20px;
    ;
    color: var(--txtColorPrimary);
    @media screen and (max-width: $mediaS) {
      width: 161px;
      font-size: 15px;
    }
  }

  &__2 {
    background-image: url("~assets/img/button/Button-2.png");
    color: var(--txtColorOptional1);
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    &--mint {
      color: var(--txtColorOptional1);
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &__3 {
    background-image: url("~assets/img/button/Button-3.png");
    color: var(--txtColorPrimary);
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);

  }

  &__4 {
    background-image: url("~assets/img/button/Button-4.png");
    color: var(--txtColorOptional3);
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

  }


}

