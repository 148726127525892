@use "variables" as *;
@use 'burger-menu';

.header {
  width: 100%;
  height: 60px;

  padding-top: 40px;
  box-sizing: content-box;

  @media screen and (min-width: $mediaS) {
    position: sticky;
    margin-bottom: -100px;
    top: -20px;
    z-index: 10;
  }

  @media screen and (max-width: $mediaS) {
    position: fixed;
    top: 0;
    z-index: 999;
  }

  &:before {
    content: '';
    z-index: -1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: linear-gradient(180deg, #141828 54.83%, rgba(20, 24, 40, 0) 100%);

    @media screen and (max-width: $mediaS) {
      background: linear-gradient(180deg, #141828 0%, rgba(20, 24, 40, 0) 100%);
    }
  }

  &__wrapper {
    display: flex;
    width: calc(100% - 40px);
    height: 100%;
    max-width: 1520px;
    align-items: center;
    margin: 0 auto;
    position: relative;
    padding: 0 20px;
    box-sizing: content-box;
    @media screen and (max-width: $mediaS) {
      padding: 0 24px 0 18px;
    }

  }

  &__logo {
    display: block;
    flex: none;
    width: 132px;
    height: 55px;
    cursor: pointer;
    margin-right: 40px;

    img {
      object-fit: contain;
      width: 100%;
      max-width: 100%;
    }
  }

  &__group-wrapper {
    display: contents;
    opacity: 0;
    @media screen and (max-width: $mediaS) {
      display: flex;
      flex-direction: column;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 0;
      width: 204px;
      background: #0C1018;
      transition: opacity 0.2s ease-in-out, 0.2s ease-in-out;
      height: 100vh;
      transform: translateX(4%);
      overflow-y: auto;
      @at-root .header.header--open {
        .header__group-wrapper {
          opacity: 1;
          pointer-events: all;
          transform: translateX(0%);

        }
      }
    }
  }


  &__group {
    display: contents;
    @media screen and (max-width: $mediaS) {
      display: block;
      margin-left: auto;
    }
  }


  &__btn {

    padding-bottom: 5px;
    display: flex;
    text-align: center;
    @media screen and (max-width: $mediaS) {
    }
  }


  &__burger {
    display: none;
    position: relative;
    z-index: 9999;
    @media screen and (max-width: $mediaS) {
      display: block;

    }
  }

  &__walletBtn{
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    margin-left: 52px;
    @media screen and (max-width: $mediaS) {
      margin-right: auto;
      margin-left: auto;
      margin-top: auto;
    }
      .connect-link{
      color: var(--txtColorSecondary);
      text-decoration: underline;
      font-weight: 600;
      position: absolute;
      top: 100%;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
        @media screen and (max-width: $mediaS) {
          position: unset;
          transform: unset;
          margin: 0 0 10px 0;
        }
      }
  }

}

.list {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 850px;
  justify-content: space-between;
  margin-left: auto;
  height: 55px;
  @media screen and (max-width: $mediaS) {
    flex-direction: column;
    width: 100%;
    height: min-content;
    flex: none;
    margin-top: 70px;
    align-items: baseline;
    padding-left: 30px;
  }

  &__item {
    flex: none;
    height: 100%;
    color: #FFFFFF;
    list-style: none;
    font-size: 20px;
    position: relative;
    transition: color .2s linear;
    text-transform: capitalize;
    @media screen and (max-width: $mediaS) {
      text-align: left;
      margin-top: 0;
      height: 50px;
      margin-bottom: 25px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 32px;
      height: 4px;
      left: 0;
      transform: translateX(100%);
      background-color: currentColor;
      opacity: 0;
      transition: opacity 0.2s, transform 0.2s;
      box-shadow: 0 0 13px #FECC46;
    }

    &:focus,
    &:hover,
    &.active {
      color: var(--txtColorActive);

    }

    &.active {
      opacity: 1;

      &::after {
        opacity: 1;
        transform: translateX(0%);
      }

      & ~ li:after {
        transform: translateX(-200%);
      }
    }

    span {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: inherit;
      text-decoration: none;
      transition: color;
      cursor: pointer;

    }
  }

}



