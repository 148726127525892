@use 'variables' as *;

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(0deg, rgba(20, 24, 40, 0.5) 54.83%, rgba(20, 24, 40, 0) 100%);
  width: 100%;
  padding: 0 var(--paddingSide);

  @media screen and (min-width: $mediaS) {
    height: 193px;
  }

  @media screen and (max-width: $mediaS) {
    height: auto;
  }

  &__in {
    max-width: 1523px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 14px 10px;

  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: none;
    height: auto;
    cursor: pointer;

    img {
      display: block;
      object-fit: contain;
      width: 80px;
      height: auto;
    }

    span {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: #FFFFFF;
      margin-left: 20px;
    }

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;

      img {
        width: 70px;
      }

      span {
        font-size: 12px;
        margin-left: 0;
      }
    }
  }

  &__link {
    display: flex;
    margin-left: auto;

    a {
      text-align: center;
      text-decoration: underline;
      color: var(--txtColorPrimary);
      margin: 0 32px;

      @media only screen and (max-width: 1024px) {
        height: 44px;
        line-height: 44px;
        font-size: 12px;
        margin: 0 8px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
