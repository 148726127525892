@font-face {
  font-family: "aller";
  src: url("../fonts/aller.regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "aller";
  src: url("../fonts/aller.bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "aller";
  src: url("../fonts/aller.light.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Tungsten";
  src: url("../fonts/Tungsten-Bold.otf");
  font-weight: bold;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

@media (hover) {
  a {
    cursor: pointer;
  }
}

app-root, app-home, app-footer, app-header, app-connect-wallet {
  display: contents;
}

:root {
  background-color: #ffffff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 40px;
  font-family: aller, sans-serif;
}
body::-webkit-scrollbar {
  width: 8px;
  height: 0;
}
body::-webkit-scrollbar-button {
  width: 6px;
  height: 0;
}
body::-webkit-scrollbar-thumb {
  background: #FECC46;
  background-clip: content-box;
}
body::-webkit-scrollbar-track {
  background: #3E4866;
}
body::-webkit-scrollbar-track:hover {
  background: #3E4866;
}
body::-webkit-scrollbar-track:active {
  background: #3E4866;
}
body::-webkit-scrollbar-corner {
  background: #3E4866;
}

:root {
  --txtColorPrimary:#ffffff;
  --txtColorSecondary:#E1E4FF;
  --txtColorActive:#FECC46;
  --txtColorInActive:#6577A3;
  --txtColorOptional1:#072942;
  --txtColorOptional2:#FFFFFF;
  --txtColorOptional3:#C7351E;
  --txtColorOptional4:#6C85C4;
  --txtColorOptional5:#FFFA8C;
  --txtColorOptional6:#979797;
  --txtColorOptional7:#196AA4;
  --paddingSide:4%;
  --paddingTop:100px;
}

.btn {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  filter: brightness(1);
  opacity: 1;
  z-index: 1;
  transition: opacity 0.2s linear, filter 0.2s linear;
}
.btn:focus, .btn:active {
  border: none;
  outline: none;
}
.btn:hover, .btn:active {
  filter: brightness(1.3);
}
.btn:disabled, .btn [aria-disabled] {
  cursor: not-allowed;
}
.btn-col {
  flex-direction: column;
}
.btn__1, .btn__2, .btn__3, .btn__4 {
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  line-height: 109.8%;
  display: flex;
  padding: 0 20px 5px 20px;
  background-size: cover;
  background-position: center;
}
.btn__1 > span, .btn__2 > span, .btn__3 > span, .btn__4 > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn__2, .btn__3, .btn__4 {
  width: 216px;
  aspect-ratio: 216/59;
  font-size: 20px;
}
.btn__2 .txt-c-s, .btn__3 .txt-c-s, .btn__4 .txt-c-s {
  font-size: 20px;
}
@media screen and (max-width: 1024px) {
  .btn__2, .btn__3, .btn__4 {
    width: 200px;
    font-size: 15px;
  }
  .btn__2 .txt-c-s, .btn__3 .txt-c-s, .btn__4 .txt-c-s {
    font-size: 12px;
  }
}
.btn__1 {
  background-image: url("~assets/img/button/Button-1.png");
  width: 216px;
  aspect-ratio: 216/59;
  font-size: 20px;
  color: var(--txtColorPrimary);
}
@media screen and (max-width: 1024px) {
  .btn__1 {
    width: 161px;
    font-size: 15px;
  }
}
.btn__2 {
  background-image: url("~assets/img/button/Button-2.png");
  color: var(--txtColorOptional1);
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.btn__2--mint {
  color: var(--txtColorOptional1);
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.btn__3 {
  background-image: url("~assets/img/button/Button-3.png");
  color: var(--txtColorPrimary);
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
}
.btn__4 {
  background-image: url("~assets/img/button/Button-4.png");
  color: var(--txtColorOptional3);
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.burger-menu__icon {
  width: 25px;
  height: 30px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.burger-menu__icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: var(--txtColorPrimary);
  border-radius: 7px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}
.burger-menu__icon span:nth-child(1) {
  top: 0;
}
.burger-menu__icon span:nth-child(2), .burger-menu__icon span:nth-child(3) {
  top: 9px;
}
.burger-menu__icon span:nth-child(4) {
  top: 18px;
}
.burger-menu__icon--close span {
  background: var(--txtColorActive);
}
.burger-menu__icon--close span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}
.burger-menu__icon--close span:nth-child(2) {
  transform: rotate(45deg);
}
.burger-menu__icon--close span:nth-child(3) {
  transform: rotate(-45deg);
}
.burger-menu__icon--close span:nth-child(4) {
  top: 9px;
  width: 0;
  left: 50%;
}

.header {
  width: 100%;
  height: 60px;
  padding-top: 40px;
  box-sizing: content-box;
}
@media screen and (min-width: 1024px) {
  .header {
    position: sticky;
    margin-bottom: -100px;
    top: -20px;
    z-index: 10;
  }
}
@media screen and (max-width: 1024px) {
  .header {
    position: fixed;
    top: 0;
    z-index: 999;
  }
}
.header:before {
  content: "";
  z-index: -1;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(180deg, #141828 54.83%, rgba(20, 24, 40, 0) 100%);
}
@media screen and (max-width: 1024px) {
  .header:before {
    background: linear-gradient(180deg, #141828 0%, rgba(20, 24, 40, 0) 100%);
  }
}
.header__wrapper {
  display: flex;
  width: calc(100% - 40px);
  height: 100%;
  max-width: 1520px;
  align-items: center;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  box-sizing: content-box;
}
@media screen and (max-width: 1024px) {
  .header__wrapper {
    padding: 0 24px 0 18px;
  }
}
.header__logo {
  display: block;
  flex: none;
  width: 132px;
  height: 55px;
  cursor: pointer;
  margin-right: 40px;
}
.header__logo img {
  object-fit: contain;
  width: 100%;
  max-width: 100%;
}
.header__group-wrapper {
  display: contents;
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .header__group-wrapper {
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 204px;
    background: #0C1018;
    transition: opacity 0.2s ease-in-out, 0.2s ease-in-out;
    height: 100vh;
    transform: translateX(4%);
    overflow-y: auto;
  }
  .header.header--open .header__group-wrapper {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0%);
  }
}
.header__group {
  display: contents;
}
@media screen and (max-width: 1024px) {
  .header__group {
    display: block;
    margin-left: auto;
  }
}
.header__btn {
  padding-bottom: 5px;
  display: flex;
  text-align: center;
}
.header__burger {
  display: none;
  position: relative;
  z-index: 9999;
}
@media screen and (max-width: 1024px) {
  .header__burger {
    display: block;
  }
}
.header__walletBtn {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  margin-left: 52px;
}
@media screen and (max-width: 1024px) {
  .header__walletBtn {
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
  }
}
.header__walletBtn .connect-link {
  color: var(--txtColorSecondary);
  text-decoration: underline;
  font-weight: 600;
  position: absolute;
  top: 100%;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%);
}
@media screen and (max-width: 1024px) {
  .header__walletBtn .connect-link {
    position: unset;
    transform: unset;
    margin: 0 0 10px 0;
  }
}

.list {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 850px;
  justify-content: space-between;
  margin-left: auto;
  height: 55px;
}
@media screen and (max-width: 1024px) {
  .list {
    flex-direction: column;
    width: 100%;
    height: min-content;
    flex: none;
    margin-top: 70px;
    align-items: baseline;
    padding-left: 30px;
  }
}
.list__item {
  flex: none;
  height: 100%;
  color: #FFFFFF;
  list-style: none;
  font-size: 20px;
  position: relative;
  transition: color 0.2s linear;
  text-transform: capitalize;
}
@media screen and (max-width: 1024px) {
  .list__item {
    text-align: left;
    margin-top: 0;
    height: 50px;
    margin-bottom: 25px;
  }
}
.list__item:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 32px;
  height: 4px;
  left: 0;
  transform: translateX(100%);
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  box-shadow: 0 0 13px #FECC46;
}
.list__item:focus, .list__item:hover, .list__item.active {
  color: var(--txtColorActive);
}
.list__item.active {
  opacity: 1;
}
.list__item.active::after {
  opacity: 1;
  transform: translateX(0%);
}
.list__item.active ~ li:after {
  transform: translateX(-200%);
}
.list__item span {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: inherit;
  text-decoration: none;
  transition: color;
  cursor: pointer;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(0deg, rgba(20, 24, 40, 0.5) 54.83%, rgba(20, 24, 40, 0) 100%);
  width: 100%;
  padding: 0 var(--paddingSide);
}
@media screen and (min-width: 1024px) {
  .footer {
    height: 193px;
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    height: auto;
  }
}
.footer__in {
  max-width: 1523px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 14px 10px;
}
.footer__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: none;
  height: auto;
  cursor: pointer;
}
.footer__logo img {
  display: block;
  object-fit: contain;
  width: 80px;
  height: auto;
}
.footer__logo span {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 20px;
}
@media only screen and (max-width: 1024px) {
  .footer__logo {
    flex-direction: column;
    justify-content: center;
  }
  .footer__logo img {
    width: 70px;
  }
  .footer__logo span {
    font-size: 12px;
    margin-left: 0;
  }
}
.footer__link {
  display: flex;
  margin-left: auto;
}
.footer__link a {
  text-align: center;
  text-decoration: underline;
  color: var(--txtColorPrimary);
  margin: 0 32px;
}
@media only screen and (max-width: 1024px) {
  .footer__link a {
    height: 44px;
    line-height: 44px;
    font-size: 12px;
    margin: 0 8px;
  }
}
.footer__link a:last-child {
  margin-right: 0;
}

body {
  display: flex;
  flex-direction: column;
  background-color: #293B21;
  position: relative;
}

.sec {
  display: flex;
  align-items: center;
  aspect-ratio: var(--ascpectRation);
}
.sec-1 {
  flex: none;
  position: relative;
  --ascpectRation: 1920/1080;
  --bgColor: #21263B;
  --bgImage: url("../img/section1/tree-bg-desktop-slice_01_1.png");
}
@media only screen and (max-width: 1024px) {
  .sec-1 {
    isolation: isolate;
    --ascpectRation: 430/943;
    --bgImage: url("../img/section1/bg-mobile.png");
    --bgColor: transparent;
    overflow: hidden;
  }
  .sec-1:before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: rgba(33, 38, 59, 0.35);
  }
}
.sec-2 {
  flex: none;
  padding: 0 var(--paddingSide);
  --ascpectRation: 1920/1080;
  --bgColor: rgba(33, 38, 59, 0.7);
  --bgImage: url("../img/section2_3_4/tree-bg-desktop-slice_02_1.png");
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .sec-2 {
    --ascpectRation: unset;
    --bgColor: transparent;
    --bgImage: none;
    padding: unset;
  }
}
.sec-3 {
  flex: none;
  padding: 7.34375% 0 0 0;
  --ascpectRation: 1920/1420;
  --bgColor: rgba(33, 38, 59, 0.7);
  --bgImage: url("../img/section2_3_4/tree-bg-desktop-slice_04_1.png");
}
@media only screen and (max-width: 1024px) {
  .sec-3 {
    --ascpectRation: unset;
    --bgColor: transparent;
    --bgImage: none;
    padding: unset;
  }
}
.sec-4 {
  flex: none;
  padding: 0 var(--paddingSide);
  --ascpectRation: 1920/1080;
  --bgColor: rgba(33, 38, 59, 0.35);
  --bgImage: url("../img/section7/tree-bg-desktop-slice_07_1.png");
}
@media only screen and (max-width: 1024px) {
  .sec-4 {
    --ascpectRation: 430/719;
    --bgColor: rgba(33, 38, 59, 0.35);
    --bgImage: url("../img/section7/tree-3_2.png");
  }
}
.sec-5 {
  flex: none;
  padding: 0 var(--paddingSide);
  --ascpectRation: 1920/3917;
  --bgColor: transparent;
  --bgImage: url("../img/section5/tree-bg-desktop-slice_05_1.png"), linear-gradient(180deg, rgba(33, 38, 59, 0.7) 0%, rgba(33, 38, 59, 0.35) 100%);
}
@media only screen and (max-width: 1024px) {
  .sec-5 {
    --ascpectRation: 430/2253;
    --bgColor: rgba(33, 38, 59, 0.35);
    --bgImage: url("../img/section5/tree-2_2.png"), url("../img/section5/tree-2_3.png");
    background-position: top, bottom;
    padding: unset;
  }
}
.sec-6 {
  flex: none;
  padding: 0 var(--paddingSide);
  --ascpectRation: 1920/1338;
  --bgColor: rgba(33, 38, 59, 0.35);
  --bgImage: url("../img/section6/tree-bg-desktop-slice_06_1.png");
}
@media only screen and (max-width: 1024px) {
  .sec-6 {
    --ascpectRation: 430/640;
    --bgColor: rgba(33, 38, 59, 0.35);
    --bgImage: url("../img/section6/tree-2_3.png");
  }
}
.sec-7 {
  flex: none;
  padding: 0 var(--paddingSide);
  position: relative;
  --ascpectRation: 1920/1080;
  --bgColor: rgba(33, 38, 59, 0.7);
  --bgImage: url("../img/section2_3_4/tree-bg-desktop-slice_03_1.png");
}
@media only screen and (max-width: 1024px) {
  .sec-7 {
    --ascpectRation: 430/719;
    --bgImage: none;
    --bgColor: rgba(33, 38, 59, 0.35);
  }
}
.sec-8 {
  position: relative;
  flex: none;
  padding: 0 var(--paddingSide);
  --ascpectRation: 1440/1003;
  --bgColor: rgba(33, 38, 59, 0.35);
  --bgImage: url("../img/section8/tree-bg-desktop-slice_08_1.png");
}
@media only screen and (max-width: 1024px) {
  .sec-8 {
    --ascpectRation: 430/1177;
    --bgColor: rgba(33, 38, 59, 0.35);
    --bgImage: url("../img/section8/tree-3_2.png");
  }
}
.sec, .sec-wrapper {
  width: 100%;
  background-color: var(--bgColor, transparent);
  background-image: var(--bgImage, none);
  background-size: 100% 100%;
}
@media only screen and (max-width: 1024px) {
  .sec, .sec-wrapper {
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.sec-d-c {
  flex-direction: column;
}
.sec-d-r {
  flex-direction: row;
}
.sec-wrapper {
  display: contents;
}
@media only screen and (max-width: 1024px) {
  .sec-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    aspect-ratio: var(--ascpectRationLG, 1);
    padding-left: 8.1395348837%;
    padding-right: 8.1395348837%;
  }
  .sec-wrapper-1 {
    --ascpectRationLG: 430/2399;
    --bgColor: rgba(33, 38, 59, 0.35);
    --bgImage: url("../img/section-group-mobile-bg.png");
    background-position: bottom, top;
  }
  .sec-wrapper .sec {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
}
@media only screen and (max-width: 430px) {
  .sec-wrapper .txt-h-2 {
    font-size: 11.6279069767vw;
  }
  .sec-wrapper .txt-c-l {
    font-size: 3.6511627907vw;
  }
}

.block {
  display: flex;
}
.block-l {
  max-width: 1581px;
}
.block-md {
  max-width: 1520px;
}
.block-sm {
  max-width: 1368px;
}
.block-xs {
  max-width: 1000px;
}
.block-center {
  margin: 0 auto;
}
.block-column {
  flex-direction: column;
}
.block-row {
  flex-direction: row;
}
.block-row-reverse {
  flex-direction: row-reverse;
}

a {
  cursor: pointer;
}

a img {
  border: 0;
}

.img {
  display: block;
  width: 100%;
  object-fit: contain;
}

.mintBtn {
  display: flex;
  flex-direction: column;
  max-width: 820px;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: min(0.6770833333vw, 13px) min(0.6770833333vw, 13px);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .mintBtn {
    gap: min(0.4166666667vw, 8px) min(0.8333333333vw, 16px);
  }
}
@media only screen and (max-width: 1024px) {
  .mintBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 680px) {
  .mintBtn {
    align-items: end;
  }
}
.mintBtn-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mintBtn-item:nth-child(2) {
  margin-left: 30px;
}
@media only screen and (max-width: 1024px) {
  .mintBtn-item:nth-child(2) {
    margin: 0 0 10px 0;
  }
}
.mintBtn-item:nth-child(3) {
  margin-left: 60px;
}
@media only screen and (max-width: 1024px) {
  .mintBtn-item:nth-child(3) {
    margin: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .mintBtn-item {
    margin-bottom: 10px;
  }
}
.mintBtn-item .available {
  font-size: 12px;
  line-height: 157%;
  color: var(--txtColorPrimary);
  font-weight: 700;
  margin-top: -4px;
}
@media only screen and (max-width: 430px) {
  .mintBtn-item .available {
    font-size: 13px;
  }
}
.mintBtn-item .amount {
  color: var(--txtColorSecondary);
  font-weight: 700;
  font-size: 12px;
  line-height: 157%;
}
@media only screen and (max-width: 430px) {
  .mintBtn-item .amount {
    font-size: 13px;
  }
}

.intro {
  width: 100%;
  align-items: center;
  position: relative;
  display: flex;
  height: 100%;
}
.intro-wrapper {
  max-width: 1760px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  height: 100%;
  padding: 0 0 0 120px;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .intro-wrapper {
    flex-direction: column;
    padding: unset;
  }
}
.intro-slider {
  height: 100%;
  flex-basis: 56%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .intro-slider {
    padding-top: 120px;
    padding-bottom: min(1.5625vw, 30px);
  }
  .intro-slider .title {
    margin-top: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .intro-slider {
    padding: 120px 20px;
    justify-content: center;
  }
}
.intro-slider .slide-description {
  margin-top: min(1.0416666667vw, 20px);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .intro-slider .slide-description {
    margin-top: min(0.7291666667vw, 14px);
  }
}
@media only screen and (max-width: 1024px) {
  .intro-slider .slide-description {
    margin-top: 18px;
  }
}
.intro-slider .countdown {
  margin-top: min(1.4583333333vw, 28px);
  margin-bottom: min(2.0833333333vw, 40px);
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: min(0.8333333333vw, 16px);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .intro-slider .countdown {
    gap: min(0.625vw, 12px);
  }
}
@media only screen and (max-width: 1024px) {
  .intro-slider .countdown {
    margin-top: 22px;
    max-width: 200px;
    flex-wrap: wrap;
    margin-bottom: 33px;
    gap: 14px;
  }
}
.intro-slider .countdown-item {
  flex: none;
  gap: 4px;
  width: min(6.9791666667vw, 134px);
  aspect-ratio: 134/156;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: min(0.625vw, 12px);
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #FFFFFF;
  box-shadow: 0 min 0.2083333333vw, 4px min(0.2083333333vw, 4px) rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .intro-slider .countdown-item {
    border-radius: min(0.4166666667vw, 8px);
    width: min(5.2083333333vw, 100px);
  }
}
@media only screen and (max-width: 1024px) {
  .intro-slider .countdown-item {
    width: 81px;
    height: 95px;
    border-radius: 8px;
    backdrop-filter: blur(3px);
  }
}
.intro-slider .countdown-item-digit {
  font-size: min(4.1666666667vw, 80px);
  line-height: 1;
  font-weight: 700;
  font-family: Tungsten, sans-serif;
  color: #FFFA8C;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .intro-slider .countdown-item-digit {
    font-size: min(2.9166666667vw, 56px);
  }
}
@media only screen and (max-width: 1024px) {
  .intro-slider .countdown-item-digit {
    font-size: 48px;
    line-height: 52px;
  }
}
.intro-slider .countdown-item-label {
  font-size: min(1.0416666667vw, 20px);
  line-height: 1;
  font-weight: 700;
  color: #979797;
  text-transform: uppercase;
  font-family: Aller;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .intro-slider .countdown-item-label {
    font-size: min(0.7291666667vw, 14px);
  }
}
@media only screen and (max-width: 1024px) {
  .intro-slider .countdown-item-label {
    font-size: 12px;
    line-height: 13px;
  }
}
.intro-btn {
  margin-top: min(3.8541666667vw, 74px);
}
.intro-social {
  display: flex;
  margin: auto 0 0 0;
}
@media only screen and (max-width: 1024px) {
  .intro-social {
    display: none;
  }
}
.intro__media {
  position: absolute;
  display: flex;
  height: 100%;
  right: 0;
}
@media only screen and (max-width: 1024px) {
  .intro__media {
    top: 80px;
    width: 100%;
    bottom: 0;
    height: auto;
  }
}
.intro__media .mediaImg {
  display: block;
  width: auto;
  height: 100%;
  max-height: 100%;
}
.intro__media .mediaImg.mobile {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .intro__media .mediaImg {
    aspect-ratio: unset;
    position: relative;
    height: min-content;
    left: 0;
    top: 0;
    width: calc(100% + 22px);
    display: none;
  }
  .intro__media .mediaImg.mobile {
    display: block;
    z-index: -2;
    top: -110px;
  }
}
.intro__media-phone {
  position: absolute;
  display: flex;
  left: 0;
  bottom: 15px;
  max-height: 655px;
  height: 90%;
  z-index: 1;
  pointer-events: none;
}
.intro__media-phone .img {
  display: block;
  height: 100%;
  width: auto;
  object-fit: contain;
  object-position: center center;
}
.intro__media-footer {
  position: absolute;
  right: 0;
  bottom: 90px;
  width: 100%;
  max-width: 600px;
  height: 68px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: rgba(38, 42, 57, 0.8);
  padding-right: 42px;
  z-index: 0;
}
.intro__media-footer .donationLogo {
  margin-left: 30px;
  height: 34px;
  width: auto;
}
.intro__media-footer .donationLogo.celo {
  height: 26px;
}
.intro__media-footer .donationLogo .logo {
  display: block;
  width: auto;
  height: 100%;
}
.intro__media-footer-desc {
  position: absolute;
  top: 100%;
  right: 42px;
  transform: translateY(8px);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px #000000;
}
@media screen and (max-width: 1024px) {
  .intro__media-phone {
    bottom: unset;
    top: 230px;
    max-height: 480px;
  }
  .intro__media-footer {
    width: 100%;
    max-width: unset;
    bottom: 40px;
  }
}
@media screen and (max-width: 680px) {
  .intro__media-phone {
    max-height: 380px;
  }
}
@media only screen and (max-width: 480px) {
  .intro__media-phone {
    max-height: 330px;
  }
}

.readmore-btn {
  margin-top: min(2.0833333333vw, 40px);
  margin-bottom: min(2.6041666667vw, 50px);
}

.about {
  width: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
  gap: min(3.2291666667vw, 62px);
}
@media only screen and (max-width: 1024px) {
  .about {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
.about__media {
  display: flex;
  aspect-ratio: 1;
  max-height: 944px;
  height: 87.4%;
}
@media only screen and (max-width: 1024px) {
  .about__media {
    aspect-ratio: 1;
    margin-bottom: 8px;
    max-width: 72%;
    flex: unset;
  }
}
.about .mediaImg {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}
.about-subtxt {
  margin-top: min(2.03125vw, 39px);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .about-subtxt {
    margin-top: min(1.4583333333vw, 28px);
  }
}
@media only screen and (max-width: 1024px) {
  .about-subtxt {
    margin-top: 18px;
  }
}
.about-mail {
  font-size: 18px;
  text-decoration: underline;
  color: var(--txtColorSecondary);
}
.about-logo {
  display: flex;
  align-items: center;
  gap: 5px;
}
.about-logo .ethichub-logo {
  vertical-align: bottom;
  width: 20%;
  height: 100%;
  display: inline-block;
  padding-left: 4px;
  margin-left: 10%;
  padding-top: 4px;
}
@media only screen and (max-width: 1024px) {
  .about-logo .ethichub-logo {
    margin-left: 4px;
    margin-right: 4px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .about-logo .ethichub-logo {
    margin-left: 0;
  }
}
.about-donation {
  display: flex;
  align-items: center;
  gap: 31px;
  width: 100%;
  margin-top: min(1.5625vw, 30px);
  margin-bottom: min(0.2604166667vw, 5px);
}
@media only screen and (max-width: 1024px) {
  .about-donation {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 15px;
  }
}
.about-donation .donationLogo {
  width: 100%;
  max-width: 114px;
}
.about-donation .donationLogo img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .about-donation .donationLogo {
    max-width: 90px;
  }
}

.show-mob {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .show-mob {
    display: block;
  }
}

.welcome {
  padding: 20px 0;
  align-items: center;
  max-width: 1352px;
  width: 70.4166666667%;
}
@media only screen and (max-width: 1024px) {
  .welcome {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.welcome__media {
  display: flex;
  width: 100%;
  max-width: 585px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  overflow: hidden;
}
.welcome__media .mediaImg {
  display: block;
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1352/759;
}
.welcome__video {
  border: 5px solid rgba(255, 255, 255, 0.3);
  display: flex;
  width: 100%;
  aspect-ratio: 560/315;
  max-width: 560px;
  overflow: hidden;
}
.welcome__video video {
  width: 100%;
  height: 100%;
}
.welcome-title {
  margin-bottom: min(1.9791666667vw, 38px);
  text-align: center;
  white-space: nowrap;
}
@media only screen and (max-width: 1024px) {
  .welcome-title {
    white-space: unset;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
  }
}

.info {
  justify-content: center;
  align-items: center;
  text-align: start;
  width: 100%;
  padding: 20px 0;
  gap: min(5.2083333333vw, 100px);
}
@media only screen and (max-width: 1024px) {
  .info {
    flex-direction: column-reverse;
    flex: unset;
  }
}
.info__media {
  display: flex;
  aspect-ratio: 1;
  max-height: 756px;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .info__media {
    aspect-ratio: 1;
    max-width: 75%;
    flex: unset;
    padding-bottom: 0px;
  }
}
.info__media .mediaImg {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.play {
  padding: 20px 0;
  width: 100%;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .play {
    flex-direction: column;
    justify-content: center;
    height: unset;
  }
}
.play__media {
  position: absolute;
  display: flex;
  aspect-ratio: 845/793;
  height: 73.42%;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 0);
}
@media only screen and (max-width: 1024px) {
  .play__media {
    position: unset;
    width: 271px;
    height: auto;
    transform: none;
    flex: unset;
    margin-bottom: 46px;
    flex-basis: unset;
    aspect-ratio: unset;
  }
}
.play__media .mediaImg {
  display: block;
  width: 100%;
  height: auto;
}
.play-reverse {
  flex-direction: row-reverse;
}
.play-btn {
  margin-top: min(3.3854166667vw, 65px);
}
.play-btn-hide {
  display: none;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .play-btn {
    margin-top: min(2.3958333333vw, 46px);
  }
}

.flex-40 {
  flex-basis: 40%;
}
.flex-50 {
  flex-basis: 50%;
}
.flex-60 {
  flex-basis: 60%;
}

.roadmap {
  position: relative;
  max-width: 1510px;
  width: 100%;
  aspect-ratio: 1510/580;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  margin: auto;
  gap: min(2.8645833333vw, 55px);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .roadmap {
    gap: min(1.9791666667vw, 38px);
    aspect-ratio: 1510/710;
  }
}
@media only screen and (max-width: 1024px) {
  .roadmap {
    display: none;
  }
}
.roadmap__title {
  text-shadow: 0 4px 10px #000000;
  margin-bottom: 18px;
}
.roadmap-vertical {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #616161;
  box-shadow: inset 0 0 min(0.5208333333vw, 10px) #FFFFFF;
  border-radius: 0.9895833333vw;
  height: 12px;
  width: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .roadmap-vertical {
    border-radius: 0.7291666667vw;
  }
}
.roadmap-vertical .progress {
  background: #FFBE11;
  box-shadow: 0 min(0.2083333333vw, 4px) min(0.8854166667vw, 17px) #FECC46, inset 0 0 0.5208333333vw #FFFFFF;
  border-radius: 0.9895833333vw;
  height: 12px;
}
.roadmap__heading {
  padding-top: min(7.34375vw, 141px);
  max-width: min(34.1666666667vw, 656px);
  align-items: center;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .roadmap__heading {
    max-width: unset;
  }
}
.roadmap .roadmapCol {
  position: relative;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  text-align: center;
}
.roadmap .roadmapCol:nth-child(odd) {
  justify-content: flex-end;
}
.roadmap .roadmapCol .avatar {
  position: relative;
  width: min(7.96875vw, 153px);
  aspect-ratio: 1;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .roadmap .roadmapCol .avatar {
    width: min(10.4166666667vw, 200px);
  }
}
.roadmap .roadmapCol .avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.roadmap .roadmapCol .avatar .avatarImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.roadmap .roadmapCol .itemNum {
  font-size: min(3.125vw, 60px);
  font-weight: 700;
  margin-top: min(0.5208333333vw, 10px);
  margin-bottom: min(1.09375vw, 21px);
}
@media only screen and (max-width: 1024px) {
  .roadmap .roadmapCol .itemNum {
    font-size: 37px;
    line-height: 60px;
    font-weight: 700;
  }
}
.roadmap .roadmapCol .dot {
  width: min(3.4375vw, 66px);
  height: min(3.4375vw, 66px);
  background: linear-gradient(180deg, #4FE72A 0%, #1E8500 115.22%);
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  flex: 0 0 min(3.4375vw, 66px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: min(1.6666666667vw, 32px);
  color: #E1E4FF;
  font-weight: 700;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .roadmap .roadmapCol .dot {
    border-width: 2px;
  }
}
.roadmap .roadmapCol .dot.active:after {
  opacity: 0;
}
.roadmap .roadmapCol .block-column {
  text-align: center;
}
.roadmap .roadmapCol .block-column p {
  max-width: 490px;
}
.roadmap__roadmap {
  padding-top: min(7.2916666667vw, 140px);
  padding-bottom: min(3.5416666667vw, 68px);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .roadmap__roadmap {
    padding-top: min(10.6976744186vw, 46px);
    padding-bottom: min(3.2558139535vw, 14px);
  }
}

.roadmap-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 20px;
  margin-top: auto;
}
@media only screen and (min-width: 1025px) {
  .roadmap-wrapper {
    display: none;
  }
}

.roadmap__mob {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 20px;
}
.roadmap__mob-item {
  background: rgba(150, 150, 150, 0.19);
  backdrop-filter: blur(2.5px);
  border-radius: 18px;
  width: 165px;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 26px 10px;
  flex: none;
}
@media only screen and (max-width: 420px) {
  .roadmap__mob-item {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .roadmap__mob-item .itemNum {
    font-size: 37px;
    line-height: 60px;
    font-weight: 700;
  }
}
.roadmap__mob .avatar-mob {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.roadmap__mob .avatar-mob .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.roadmap__mob .txt-mob {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.99);
  overflow-x: hidden;
  overflow-y: auto;
}
.popup-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  outline: none;
  padding: 8px;
  display: block;
  border: none;
  background: none;
  opacity: 0.5;
  cursor: pointer;
}
.popup-btn svg {
  display: block;
  height: 34px;
  width: auto;
}
@media screen and (max-width: 1024px) {
  .popup-btn {
    top: 20px;
    right: 20px;
  }
  .popup-btn svg {
    height: 28px;
  }
}
.popup-btn:hover {
  opacity: 1;
}
.popup__wrapper {
  display: flex;
  width: 100%;
  padding: min(4.4270833333vw, 85px) min(6.7708333333vw, 130px) min(4.9479166667vw, 95px) min(6.7708333333vw, 130px);
  gap: min(5.5208333333vw, 106px);
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  max-width: 1666px;
  max-height: 908px;
  margin: auto;
}
@media only screen and (max-width: 1024px) {
  .popup__wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    text-align: center;
    max-width: 1666px;
    max-height: unset;
    aspect-ratio: unset;
  }
}
.popup__wrapper-media {
  flex-direction: column;
  justify-content: flex-start;
}
.popup__wrapper-media .popup-wrapper--content {
  position: absolute;
  top: 24px;
  height: 50px;
  line-height: 50px;
  left: 0;
  width: 100%;
  text-align: center;
  user-select: none;
  pointer-events: none;
  z-index: 1;
}
@media only screen and (max-width: 1024px) {
  .popup__wrapper-media .popup-wrapper--content {
    top: 0;
    height: min(19.7674418605vw, 85px);
    line-height: min(19.7674418605vw, 85px);
    font-size: 28px;
  }
}
.popup__wrapper__game {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .popup-content .txt-c-s {
    font-size: min(1.0416666667vw, 20px);
  }
}
@media only screen and (max-width: 1024px) {
  .popup-content {
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    flex: unset;
  }
  .popup-content h2 {
    font-size: min(13.9534883721vw, 60px);
    margin-bottom: min(4.4186046512vw, 19px);
  }
}
.popup-content-link {
  text-transform: capitalize;
  text-decoration: underline;
  padding-top: 20px;
}
.popup-media {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
  object-position: center center;
}
.popup-media-wrap {
  width: 80vw;
  height: 70vh;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  overflow: hidden;
}

.info-popup__wrapper {
  width: 70%;
}
.info-popup-content {
  text-align: center;
  align-items: center;
}

body.webPopup-container {
  overflow: hidden;
}

.titleBlock-item-mt-xl {
  padding-top: 7.34375%;
}
.titleBlock-item-mt-l {
  margin-top: min(5.2083333333vw, 100px);
}
.titleBlock-item-mt-m {
  margin-top: 39px;
}
.titleBlock-item-mt-xl {
  margin-top: 156px;
}
.titleBlock-item-mt-s {
  margin-top: 31px;
}
.titleBlock-item-mt-xs {
  margin-top: min(0.9375vw, 18px);
}
.titleBlock-item-mb-s {
  margin-bottom: 31px;
}
.titleBlock-item-mb-l {
  margin-bottom: 39px;
}
@media only screen and (max-width: 1024px) {
  .titleBlock-item-mb-l {
    margin-bottom: 18px;
  }
}
.titleBlock-item-mb-m {
  margin-bottom: 55px;
}
@media only screen and (max-width: 1024px) {
  .titleBlock-item-mb-m {
    margin-bottom: 30px;
  }
}
.titleBlock-item-mb-xl {
  margin-bottom: 74px;
}
.titleBlock-item-ml-s {
  margin-left: 30px;
}
@media only screen and (max-width: 1024px) {
  .titleBlock-item-ml-s {
    margin-left: 0;
  }
}
.titleBlock-item-mr-s {
  margin-right: 30px;
}

.titleBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  position: relative;
  z-index: 9;
  max-width: 568px;
}
.titleBlock-intro {
  justify-content: center;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .titleBlock-intro {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .titleBlock-intro br {
    display: none;
  }
}
.titleBlock h1 {
  text-shadow: 0 4px 10px #000000;
}
.titleBlock-about {
  flex-basis: 50%;
}
.titleBlock-about p {
  color: var(--txtColorSecondary);
}
@media only screen and (max-width: 1024px) {
  .titleBlock-about {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.titleBlock-welcome {
  padding-top: 7.4294205052%;
  align-items: center;
  text-align: center;
}
.titleBlock-welcome p {
  text-align: center;
  max-width: 800px;
}
@media only screen and (max-width: 1024px) {
  .titleBlock-welcome {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: unset;
  }
  .titleBlock-welcome br {
    display: none;
  }
}
.titleBlock-info {
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .titleBlock-info {
    text-align: center;
  }
}
.titleBlock-info__title {
  margin-bottom: 18px;
}
.titleBlock-play {
  height: 100%;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .titleBlock-play {
    justify-content: flex-start;
    text-align: center;
    align-items: center;
  }
}
.titleBlock-40 {
  flex-basis: 40%;
}
.titleBlock-50 {
  flex-basis: 50%;
}
.titleBlock-60 {
  flex-basis: 60%;
}
.titleBlock .subtitle-intro {
  color: var(--txtColorSecondary);
  margin-top: min(0.3125vw, 6px);
  margin-bottom: min(2.5520833333vw, 49px);
}
.titleBlock .subtitle-intro-0 {
  margin-bottom: 0;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .titleBlock .subtitle-intro {
    margin-top: min(1.1458333333vw, 22px);
    margin-bottom: min(1.5625vw, 30px);
  }
}
@media only screen and (max-width: 1024px) {
  .titleBlock .subtitle-intro {
    margin-top: 18px;
    margin-bottom: 26px;
  }
}

.txt {
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
}
.txt-h {
  font-family: Tungsten, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.txt-h-2 {
  font-size: min(5.2083333333vw, 100px);
  line-height: 97.8%;
}
@media only screen and (max-width: 1024px) {
  .txt-h-2 {
    font-size: 60px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-h-2 {
    font-size: min(5.5208333333vw, 106px);
  }
}
.txt-h-3 {
  font-size: min(4.1666666667vw, 80px);
  line-height: 97.8%;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-h-3 {
    font-size: min(4.7916666667vw, 92px);
  }
}
@media only screen and (max-width: 1024px) {
  .txt-h-3 {
    font-size: 50px;
  }
}
.txt-h-4 {
  font-size: min(5.2083333333vw, 100px);
  line-height: 109.8%;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-h-4 {
    font-size: min(3.6458333333vw, 70px);
  }
}
@media only screen and (max-width: 1024px) {
  .txt-h-4 {
    font-size: 50px;
  }
}
.txt-h-capitalize {
  text-transform: capitalize;
}
.txt-c {
  font-family: aller, sans-serif;
  font-weight: 200;
}
.txt-c-xl {
  font-weight: 400;
  font-size: min(3.0208333333vw, 58px);
  line-height: 109.8%;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-c-xl {
    font-size: min(2.0833333333vw, 40px);
  }
}
@media only screen and (max-width: 1024px) {
  .txt-c-xl {
    font-size: 28px;
  }
}
.txt-c-l {
  font-weight: 400;
  font-size: min(1.3541666667vw, 26px);
  line-height: 153.3%;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-c-l {
    font-size: min(1.3020833333vw, 25px);
  }
}
@media only screen and (max-width: 1024px) {
  .txt-c-l {
    font-size: min(4.1860465116vw, 18px);
  }
}
.txt-c-m {
  font-size: min(1.3541666667vw, 26px);
  line-height: 153.3%;
  font-weight: 400;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-c-m {
    font-size: min(1.3020833333vw, 25px);
  }
}
@media only screen and (max-width: 1024px) {
  .txt-c-m {
    font-size: 28px;
  }
}
.txt-c-subTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 109.8%;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-c-subTitle {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .txt-c-subTitle {
    font-size: 18px;
  }
}
.txt-c-title {
  font-weight: 700;
  font-size: min(1.5625vw, 30px);
  line-height: 157.3%;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-c-title {
    font-size: min(1.3541666667vw, 26px);
    font-weight: 700;
  }
}
@media only screen and (max-width: 1024px) {
  .txt-c-title {
    font-size: 22px;
    line-height: 25px;
  }
}
.txt-c-s {
  font-size: min(1.0416666667vw, 20px);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .txt-c-s {
    font-size: min(0.8333333333vw, 16px);
  }
}
@media screen and (max-width: 1320px) {
  .txt-c-s {
    font-size: 13px;
  }
}
.txt-c-xs {
  font-size: 18px;
  font-weight: 400;
  line-height: 157.3%;
}
@media screen and (max-width: 1320px) {
  .txt-c-xs {
    font-size: 16px;
    line-height: 23px;
  }
}
.txt-uppercase {
  text-transform: uppercase;
}
.txt-w-700 {
  font-weight: 700;
}

.txt-primary {
  color: var(--txtColorPrimary);
}

.txt-secondary {
  color: var(--txtColorSecondary);
}

.team.teamWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.team-heading {
  justify-content: center;
  text-align: center;
  align-items: center;
}

.teamItems {
  width: 100%;
  padding: min(2.03125vw, 39px) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: min(1.1979166667vw, 23px);
}
@media only screen and (max-width: 1024px) {
  .teamItems {
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: flex-start;
    gap: min(6.976744186vw, 30px);
    margin: 0 calc(-1 * var(--paddingSide));
    padding-left: var(--paddingSide);
    padding-right: var(--paddingSide);
    width: auto;
    max-width: 100vw;
    padding-top: 25px;
    padding-bottom: 80px;
  }
}
.teamItems__item {
  position: relative;
  display: flex;
  flex-direction: column;
  transform: scale(0.8888888889) translate3d(0, 0, 0);
  transform-origin: center center;
  filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.5));
}
@media only screen and (max-width: 1024px) {
  .teamItems__item {
    transform: translate3d(0, 0, 0);
    overflow: visible;
    filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.5));
  }
}
.teamItems__item .hide {
  display: none;
}
.teamItems__item:hover {
  transform: scale(1) translate3d(0, 0, 0);
}
.teamItems__media {
  aspect-ratio: 231/330;
  max-width: min(19.6354166667vw, 377px);
  width: 100%;
  background: linear-gradient(180deg, #FA7777 0%, #FFC700 100%), #FF7D7D;
  border-radius: min(1.0416666667vw, 20px);
  overflow: hidden;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .teamItems__media {
    max-width: 100%;
    flex: none;
    width: 231px;
    height: 430px;
    border-radius: min(4.1860465116vw, 18px);
  }
  .teamItems__media:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    aspect-ratio: 271/202;
    background: linear-gradient(360deg, #6577A3 -3.04%, rgba(101, 119, 163, 0) 81.74%);
  }
}
.teamItems__media .mediaImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.teamItems__media .mediaImg .active {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.teamItems__title {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  text-align: left;
  padding: 10px 10px 16px 11px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  z-index: 999;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .teamItems__title {
    padding: 5px 5px 5px 10px;
  }
}
.teamItems__social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.teamItems__item {
  overflow: hidden;
}
@media only screen and (max-width: 1024px) {
  .teamItems__item {
    overflow: visible;
  }
}
.teamItems__item .title {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
  width: min(14.6354166667vw, 281px);
  padding-bottom: 10px;
}
.teamItems__item .ceo {
  margin-bottom: min(0.5208333333vw, 10px);
  font-size: 18px;
  line-height: 157.3%;
  color: #FFFFFF;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .teamItems__item .ceo {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1024px) {
  .teamItems__item .ceo {
    font-size: 13px;
  }
}

.timeline {
  position: relative;
  max-width: 1358px;
  width: 100%;
  height: fit-content;
  justify-content: center;
  margin: auto;
  gap: 15px;
}
@media only screen and (max-width: 1024px) {
  .timeline {
    margin: unset;
    padding: 0 min(4.8837209302vw, 21px) min(11.6279069767vw, 50px);
    flex: 1;
  }
}
.timeline-horizontal {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background: #616161;
  box-shadow: inset 0 0 10px #FFFFFF;
  border-radius: 0.9895833333vw;
  height: 100%;
  width: 12px;
  z-index: 1;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .timeline-horizontal {
    border-radius: 0.7291666667vw;
  }
}
@media only screen and (max-width: 1024px) {
  .timeline-horizontal {
    width: 5px;
    box-shadow: inset 0 0 4px #FFFFFF;
    border-radius: 8px;
  }
}
.timeline-horizontal .progress {
  background: #FFBE11;
  box-shadow: 0 min(0.2083333333vw, 4px) min(0.8854166667vw, 17px) #FECC46, inset 0 0 0.5208333333vw #FFFFFF;
  border-radius: 0.9895833333vw;
  width: 12px;
}
@media only screen and (max-width: 1024px) {
  .timeline-horizontal .progress {
    width: 5px;
    box-shadow: inset 0 0 4px #FFFFFF;
    border-radius: 8px;
  }
}
.timeline .timelineRow {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}
.timeline .timelineRow:nth-child(odd) {
  flex-direction: row-reverse;
}
.timeline .timelineRow:nth-child(odd) .avatar-container {
  justify-content: flex-start;
}
.timeline .timelineRow:nth-child(odd) .block-column {
  text-align: right;
  align-items: flex-end;
}
.timeline .timelineRow:nth-child(odd) .content {
  background: linear-gradient(90deg, rgba(25, 25, 33, 0) 6.92%, #191921 60.47%);
  padding: 25px 60px 29px 33px;
  border-radius: 20px 0 0 20px;
  transform: translateX(calc(min(5.2083333333vw, 100px) + min(1.0416666667vw, 20px)));
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .timeline .timelineRow:nth-child(odd) .content {
    transform: translateX(calc(min(3.6458333333vw, 70px) + min(1.0416666667vw, 20px)));
  }
}
@media only screen and (max-width: 1024px) {
  .timeline .timelineRow:nth-child(odd) .content {
    background: unset;
    transform: unset;
    padding: 0;
  }
}
.timeline .timelineRow .avatar {
  position: relative;
  width: min(17.0833333333vw, 328px);
  aspect-ratio: 1.0900900901;
}
@media only screen and (max-width: 1024px) {
  .timeline .timelineRow .avatar {
    width: 128px;
  }
}
.timeline .timelineRow .avatar-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  max-width: 460px;
}
.timeline .timelineRow .avatar .avatarImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  -webkit-mask-size: contain;
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center center;
  -webkit-mask-position: center center;
  -webkit-mask-image: url(/assets/img/timeline/mask-border.svg#mask-border);
  mask-image: url(/assets/img/timeline/mask-border.svg#mask-border);
}
.timeline .timelineRow .dot {
  width: min(2.0833333333vw, 40px);
  height: min(2.0833333333vw, 40px);
  background: linear-gradient(180deg, #4FE72A 0%, #1E8500 115.22%);
  border: 2px solid #FFFAE2;
  border-radius: 50%;
  display: flex;
  flex: 0 0 min(2.0833333333vw, 40px);
  margin: 0 min(5.2083333333vw, 100px);
  position: relative;
  z-index: 2;
}
.timeline .timelineRow .dot.active:after {
  opacity: 0;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .timeline .timelineRow .dot {
    margin: min(3.6458333333vw, 70px);
    border-width: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .timeline .timelineRow .dot {
    width: 18px;
    height: 18px;
    border: 1px solid #FFFAE2;
    flex: unset;
  }
}
.timeline .timelineRow .block-column {
  flex: 1 0 0;
}
.timeline .timelineRow .block-column p {
  max-width: 490px;
}
@media screen and (max-width: 1024px) {
  .timeline .timelineRow .block-column p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.timeline .timelineRow .content {
  background: linear-gradient(90deg, #191921 37.02%, rgba(25, 25, 33, 0) 97.5%);
  border-radius: 0 20px 20px 0;
  padding: 25px 33px 29px 60px;
  max-height: 253px;
  max-width: 460px;
  transform: translateX(calc(-1 * min(5.2083333333vw, 100px) - min(1.0416666667vw, 20px)));
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .timeline .timelineRow .content {
    transform: translateX(calc(-1 * min(3.6458333333vw, 70px) - min(1.0416666667vw, 20px)));
  }
}
@media only screen and (max-width: 1024px) {
  .timeline .timelineRow .content {
    background: unset;
    transform: unset;
    padding: 0;
  }
}
.timeline .timelineRow .content p {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 400;
}
.timeline__title {
  padding-top: min(7.2916666667vw, 140px);
  padding-bottom: min(3.5416666667vw, 68px);
  text-shadow: 0 4px 10px #000000;
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .timeline__title {
    padding-top: min(5.1041666667vw, 98px);
    padding-bottom: min(2.5vw, 48px);
  }
}
@media only screen and (max-width: 1024px) {
  .timeline__title {
    padding-bottom: 70px;
  }
}

.mb-s {
  margin-bottom: min(0.625vw, 12px);
}
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .mb-s {
    margin-bottom: min(1.8604651163vw, 8px);
  }
}

.mb-xs {
  margin-bottom: min(0.4166666667vw, 8px);
}

.loader-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border-bottom: 4px solid #C7351E;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.privacy {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #141828 54.83%, rgba(20, 24, 40, 0) 100%);
}
.privacy__wrapper {
  max-width: 1560px;
  width: 100%;
  display: flex;
  text-align: start;
  justify-content: center;
  align-items: center;
  padding: 150px 20px 150px 20px;
}
.privacy__wrapper div {
  color: var(--txtColorSecondary);
}
.privacy span {
  display: inline-block;
}
.privacy .ml-s {
  margin-left: 40px;
}
.privacy .ml-m {
  margin-left: 80px;
}
.privacy .ml-l {
  margin-left: 120px;
}
@media all and (max-width: 1024px) {
  .privacy .ml-s {
    margin-left: 20px;
  }
  .privacy .ml-m {
    margin-left: 40px;
  }
  .privacy .ml-l {
    margin-left: 60px;
  }
}

.toast-container {
  font-size: 20px;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}