@use 'variables' as *;

.titleBlock-item {
  &-mt-xl {
    padding-top: 7.34375%;
  }

  &-mt-l {
    margin-top: min(100 * $wp, 100px);
  }

  &-mt-m {
    margin-top: 39px;
  }

  &-mt-xl {
    margin-top: 156px;
  }

  &-mt-s {
    margin-top: 31px;
  }

  &-mt-xs {

    margin-top: min(18 * $wp, 18px);
  }

  &-mb-s {
    margin-bottom: 31px;
  }

  &-mb-l {
    margin-bottom: 39px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 18px;
    }
  }

  &-mb-m {
    margin-bottom: 55px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 30px;
    }
  }

  &-mb-xl {
    margin-bottom: 74px;
  }

  &-ml-s {
    margin-left: 30px;
    @media only screen and (max-width: 1024px) {
      margin-left: 0;
    }
  }

  &-mr-s {
    margin-right: 30px;
  }
}


.titleBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  position: relative;
  z-index: 9;
  max-width: 568px;

  &-intro {
    justify-content: center;
    height: 100%;
    @media only screen and (max-width: 1024px) {
      text-align: center;
      align-items: center;
      justify-content: center;
      br {
        display: none;
      }
    }
  }

  h1 {
    text-shadow: 0 4px 10px #000000;
  }

  &-about {
    flex-basis: 50%;

    p {
      color: var(--txtColorSecondary);
    }


    @media only screen and (max-width: 1024px) {
      justify-content: center;
      align-items: center;
      text-align: center;



    }
  }

  &-welcome {
    p {
      text-align: center;
      max-width: 800px;
    }

    padding-top: 7.4294205052%;
    align-items: center;
    text-align: center;
    @media only screen and (max-width: 1024px) {
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-top: unset;

      br {
        display: none;
      }
    }
  }

  &-info {
    justify-content: center;

    @media only screen and (max-width: 1024px) {
      text-align: center;

    }

    &__title {
      margin-bottom: 18px;
    }
  }

  &-play {
    height: 100%;
    justify-content: center;
    @media only screen and (max-width: 1024px) {
      justify-content: flex-start;
      text-align: center;
      align-items: center;
    }
  }

  &-40 {
    flex-basis: 40%;
  }

  &-50 {
    flex-basis: 50%;
  }

  &-60 {
    flex-basis: 60%;
  }

  .subtitle-intro {
    color: var(--txtColorSecondary);
    margin-top: min(6 * $wp, 6px);
    margin-bottom: min(49 * $wp, 49px);

    &-0 {
      margin-bottom: 0;
    }

    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      margin-top: min(22 * $wp, 22px);
      margin-bottom: min(30 * $wp, 30px);
    }
    @media only screen and (max-width: 1024px) {
      margin-top: 18px;
      margin-bottom: 26px;
    }
  }

}














