.burger-menu {
  &__icon {
    width: 25px;
    height: 30px;
    position: relative;
    margin: 0 auto;
    transform: rotate(0deg);
    transition: .2s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 100%;
      background: var(--txtColorPrimary);
      border-radius: 7px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .2s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2), &:nth-child(3) {
        top: 9px;
      }
      &:nth-child(4) {
        top: 18px;
      }
    }

    &--close {
      span {
        background: var(--txtColorActive);

        &:nth-child(1) {
          top: 9px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 9px;
          width: 0;
          left: 50%;
        }
      }
    }
  }
}
