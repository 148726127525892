.privacy {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #141828 54.83%, rgba(20, 24, 40, 0) 100%);

  &__wrapper {
    max-width: 1560px;
    width: 100%;
    display: flex;
    text-align: start;
    justify-content: center;
    align-items: center;
    padding: 150px 20px 150px 20px;

    div {
      color: var(--txtColorSecondary);
    }
  }

  span {
    display: inline-block;
  }


  .ml-s {
    margin-left: 40px;
  }

  .ml-m {
    margin-left: 80px;
  }

  .ml-l {
    margin-left: 120px;
  }

  @media all and (max-width: 1024px) {
    .ml-s {
      margin-left: 20px;
    }

    .ml-m {
      margin-left: 40px;
    }

    .ml-l {
      margin-left: 60px;
    }
  }
}


