@use 'variables' as *;


.team {
  //@media only screen and (max-width: 1024px){
  //  display: none;
  //}
  &.teamWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &-heading {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}

.teamItems {
  width: 100%;
  padding: min(39 * $wp, 39px) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: min(23 * $wp, 23px);

  @media only screen and (max-width: 1024px) {
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: flex-start;
    gap: min(30 * $wpm, 30px);
    margin: 0 calc(-1 * var(--paddingSide));
    padding-left: var(--paddingSide);
    padding-right: var(--paddingSide);
    width: auto;
    max-width: 100vw;
    padding-top: 25px;
    padding-bottom: 80px;
  }


  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    transform: scale(.888888888889) translate3d(0, 0, 0);
    transform-origin: center center;
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.5));

    @media only screen and (max-width: 1024px) {
      transform: translate3d(0, 0, 0);
      overflow: visible;
      filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.5));
    }

    .hide {
      display: none;
    }

    &:hover {
      transform: scale(1) translate3d(0, 0, 0);
    }

  }

  &__media {
    aspect-ratio: 231/330;
    max-width: min(377 * $wp, 377px);
    width: 100%;
    background: linear-gradient(180deg, #FA7777 0%, #FFC700 100%), #FF7D7D;
    border-radius: min(20 * $wp, 20px);
    overflow: hidden;
    position: relative;
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
      flex: none;
      width: 231px;
      height: 430px;
      border-radius: min(18 * $wpm, 18px);
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        aspect-ratio: 271/202;
        background: linear-gradient(360deg, #6577A3 -3.04%, rgba(101, 119, 163, 0) 81.74%);
      }

    }

    .mediaImg {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      .active {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    text-align: left;
    padding: 10px 10px 16px 11px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    z-index: 999;
    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      padding: 5px 5px 5px 10px;
    }

  }


  &__social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }


}


.teamItems__item {

  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    overflow: visible;
  }

  .title {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    left: 50%;
    transform: translate(-50%);
    width: min(281 * $wp, 281px);
    padding-bottom: 10px;


  }

  .ceo {
    margin-bottom: min(10 * $wp, 10px);
    font-size: 18px;
    line-height: 157.3%;
    color: #FFFFFF;
    @media screen and (min-width: $mediaS) and (max-width: $mediaM) {
      font-size: 13px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 13px;
    }

  }

}



