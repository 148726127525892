@font-face {
  font-family: "aller";
  src: url("../fonts/aller.regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "aller";
  src: url("../fonts/aller.bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "aller";
  src: url("../fonts/aller.light.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Tungsten";
  src: url("../fonts/Tungsten-Bold.otf");
  font-weight: bold;
}
